/* eslint-disable no-useless-escape */
export default {
	currEnv: 'uat2',
	timeout: 1000,
	maxLogs: 50,
	maxInQuery: 10,
	view: {
		ADMIN: "Admin",
		COMPANY: "Company"
	},
	requestSource: {
		CMS: 'CMS',
		DISPATCH_RECEIPT: 'Dispatch Receipt',
		INVENTORY: 'Inventory',
		STICKER_PRINTER: 'Sticker Printer'
	},
	firebaseAuthLink: 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=',
	adminAccount: {
		TAWI_SUPPORT: 'support@tawitech.ph'
	},
	superAdminRole: 'Super Admin',
	managerRole: 'Manager',
	supervisorRole: 'Supervisor',
	viewerRole: 'Viewer',
	scannerRole: 'Scanner',
	companyNameRegex: /^[ÑñA-Za-z0-9&",.! '-]{2,100}$/,
	locationNameRegex: /^[ÑñA-Za-z0-9&",.! '-]{2,100}$/,
	nameRegex: /^([ÑñA-Za-z- .']{1,30})$/,
	positionRegex: /^[ÑñA-Za-z0-9&,. '-]{2,60}$/,
	fullNameRegex: /^([ÑñA-Za-z- .']{1,100})$/,
	contactNoRegex: /\(\+63\s?\d{1,2}\)\s?\d{4}\s?\d{4}|^[+]639[0-9]{9}$|^(\([0-9]{2,3}\)\s?[0-9]{3}[-\s][0-9]{4}\/)?\([0-9]{2,3}\)[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}$/,
	emailRegex: /^[\w.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
	employeeNoRegex: /^([0-9]{1,12})$/,
	passwordRegex: /[A-Za-z0-9<>().,"'=:;@$%*?!&_\-+|]{8,}/,
	remarksRegex: /^[ÑñA-Za-z0-9\\s&'"\-,.\/():;!*[\]@ ]{1,200}$/,
	addressRegex: /^(?=.*[ÑñA-Za-z ])[ÑñA-Za-z\d\s(),.#'-]+$/,
	plateNoRegex: /^[A-Z]{3,4}-\d{3,4}$|^\d{2}-\d{9}$|^[A-Z\d]{3,5}-?\d{3,5}$/,
	assetCodeRegex: /^[A-Z]{1,4}[a-hj-km-np-z0-9]{6}$/,
	brandRegex: /^[A-Za-z0-9]{1,5}$/,
	branchCodeRegex: /^[ÑA-Za-z0-9][ñA-Za-z0-9\s&'\-,.\/]{1,10}$/,
	serialNoRegex: /^[A-Z0-9]+$/,
	conditionRegex: /^[A-Za-z0-9 ]{1,15}$/,
	passwordErrorMsg: 'Password must have at least eight (8) characters and should contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character',
	maxBatchSize: 400,
	companyTypes: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Asset User',
			text: 'Asset User'
		},
		{
			value: 'Asset Service Provider',
			text: 'Asset Service Provider'
		},
		{
			value: 'Hybrid',
			text: 'Hybrid'
		}
	],
	assetStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Inactive',
			text: 'Inactive'
		},
		{
			value: 'Stored',
			text: 'Stored'
		}, {
			value: 'In-Transit',
			text: 'In-Transit'
		}, {
			value: 'Lost',
			text: 'Lost'
		}
	],
	changeLocationPurpose: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Wrong Input',
			text: 'Wrong Input'
		},
		{
			value: 'Unscanned Assets',
			text: 'Unscanned Assets'
		}, {
			value: 'Others',
			text: 'Others'
		}
	],
	dispatchStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Draft',
			text: 'Draft'
		},
		{
			value: 'In-Transit',
			text: 'In-Transit'
		},
		{
			value: 'Receiving',
			text: 'Receiving'
		},
		{
			value: 'Received',
			text: 'Received'
		},
		{
			value: 'Cancelled',
			text: 'Cancelled'
		},
	],
	receiptStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'In-Transit',
			text: 'In-Transit'
		},
		{
			value: 'Receiving',
			text: 'Receiving'
		},
		{
			value: 'Received',
			text: 'Received'
		}
	],
	inventorySessionStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Draft',
			text: 'Draft'
		},
		{
			value: 'On-Going',
			text: 'On-Going'
		}, {
			value: 'Done',
			text: 'Done'
		}, {
			value: 'Cancelled',
			text: 'Cancelled'
		}
	],
	assetConditionStatus: [
		{
			value: null,
			text: ' - Please select - '
		},
		{
			value: 'Good',
			text: 'Good'
		},
		{
			value: 'Damaged',
			text: 'Damaged'
		}
	],
	assetHistoryOperation: {
		CREATE_NEW_ASSET: 'CREATE_NEW_ASSET',
		ACTIVATE_ASSET: 'ACTIVATE_ASSET',
		ASSIGN_CONDITION_ON_ASSET: 'ASSIGN_CONDITION_ON_ASSET',
		CHANGE_ASSET_LOCATION: 'CHANGE_ASSET_LOCATION',
		MARK_ASSET_AS_LOST: 'MARK_ASSET_AS_LOST',
		DISPATCH_ASSET: 'DISPATCH_ASSET',
		CANCEL_DISPATCH_ASSET: 'CANCEL_DISPATCH_ASSET',
		RECEIVE_ASSET: 'RECEIVE_ASSET',
		IMPORT_ASSET: 'IMPORT_ASSET',
		ASSET_INVENTORY: 'ASSET_INVENTORY',
		UPDATE_ASSET_DETAILS: 'UPDATE_ASSET_DETAILS',
	},
	assetTagging: {
		DEFAULT: 'Default',
		CUSTOM: 'Custom'
	},
	assetCondition: {
		GOOD: 'Good',
		DAMAGED: 'Damaged'
	},
	assetIssuanceStatus: {
		ISSUED: 'Issued',
		RETURNED: 'Returned',
		CANCELLED: 'Cancelled'
	},
	statusOptions: [
		{ text: 'Active', value: "true" },
		{ text: 'Inactive', value: "false" },
	],
	deviceTypeOptions: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Mobile', value: "mobile" },
		{ text: 'Web', value: "web" },
	],
	statusOptionsWithDefault: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Active', value: "true" },
		{ text: 'Inactive', value: "false" },
	],
	requestStatusOptions: [
		{ text: ' - Please select - ', value: null },
		{ text: 'Open', value: 'Open' },
		{ text: 'Approved', value: 'Approved' },
		{ text: 'Rejected', value: 'Rejected' },
	],
	assetTaggingOptions: [
		{ text: 'Default', value: "Default" },
		{ text: 'Custom', value: "Custom" },
	],
	dropdownDefaultValue: {
		value: null,
		text: ' - Please select - '
	},
	companyDefaultValue: {
		id: null,
		isActive: null,
		name: ' - Please select - ',
		type: null,
		brand: null
	},
	storageLocationDefaultValue: {
		id: null,
		name: ' - Please select - ',
		geoaddress: null,
		companyId: null,
		text: ' - Please select - '
	},
	transportationDefaultValue: {
		id: null,
		name: ' - Please select - ',
		company: null,
		companyId: null,
		text: ' - Please select - '
	},
	transportationOthersValue: {
		id: null,
		name: ' - Others - ',
		company: null,
		companyId: null,
		text: ' - Others - '
	},
	assetTypeDefaultValue: {
		id: null,
		name: ' - Please select - ',
		origin: null,
		originId: null,
		text: ' - Please select - '
	},
	assetTypeCodeDefaultValue: {
		id: null,
		name: ' - Please select - ',
		prefix: null,
		text: ' - Please select - '
	},
	userDefaultValue: {
		id: null,
		name: ' - Please select - ',
		companyId: null,
		type: null,
		text: ' - Please select - '
	},
	dispatchCreationSource: {
		DISPATCH: 'Dispatch',
		RECEIPT: 'Receipt',
		PUSHPULL: 'PushPull'
	},
	dispatchInputAssetLogDefaultValue: {
		dispatch: { scan: [], manual: [], auto: [] },
		receipt: { scan: [], manual: [], auto: [] }
	},
	inputAssetLogDefaultValue: { scan: [], manual: [], auto: [] },
	notificationType: {
		LOCKED_ACCOUNT: 'LOCKED_ACCOUNT',
		NEW_COMPANY: 'NEW_COMPANY',
		DISPATCH_DEPLOYED: 'DISPATCH_DEPLOYED',
		DISPATCH_RECEIVED: 'DISPATCH_RECEIVED',
		DISPATCH_IN_TRANSIT: 'DISPATCH_IN_TRANSIT',
		CHANGE_LOCATION: 'CHANGE_LOCATION'
	},
	permissionsDefaultValues: {
		cms: true,
		inventory: true,
		dispatch: true,
		sticker: false,
	},
	dispatchTransportDefaultValue: {
		plateNo: '',
		company: '',
		companyId: '',
	},
	dispatchDriverDefaultValue: {
		name: '',
		licenseUrl: '',
		assistants: '',
	},
	cameraDefaultValues: {
		top: false,
		back: true
	},
	userTypes: [
		{ value: null, text: ' - Please select - ' },
		{ value: 'Manager', text: 'Manager' },
		{ value: 'Supervisor', text: 'Supervisor' },
		{ value: 'Scanner', text: 'Scanner' },
		{ value: 'Viewer', text: 'Viewer' },
	],
	maxQRCodeStrLength: 30,
	areaRadiusDefaultValue: 100,
	dateFormatOptions: {
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		weekday: 'short',
	}
};
